import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { CollectionService } from '../point/collection.service';
import { take } from 'rxjs';
import { CollectionContentDTO } from '../../model/collection-dto';
import { environment } from 'app_code/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MetaTagUpdateService {

    constructor(
        private meta: Meta,
        private collectionService: CollectionService) {
    }

    init(collectionId: number) {
        if (!collectionId) {
            this.setDefaultMetaTags();
        } else {
            this.collectionService.getCollection(collectionId).pipe(
                take(1)
            ).subscribe((collection: CollectionContentDTO) => {
                this.update(collection);
            })
        }
    }

    update(collection: CollectionContentDTO) {
        if (!collection) {
            this.setDefaultMetaTags();
        } else {
            this.setMetaTags(collection);
        }
    }

    private setDefaultMetaTags(): void {
        // Open graph FB/Instagram/Linkedin
        this.meta.updateTag({ property: 'og:title', content: 'Spaeshout' });
        this.meta.updateTag({ property: 'og:type', content: 'website' });
        this.meta.updateTag({ property: 'og:url', content: 'https://spceshout.com' });
        // this.meta.updateTag({ property: 'og:image', content: 'Spaeshout' }); // TO-DO set default page image
        this.meta.updateTag({ property: 'og:description', content: 'Spaceshout – add unique places on maps, creating one-of-a-kind collections, and then share them with others in a very simple way.' });
        
        this.meta.updateTag({ property: 'og:site_name', content: 'Spaeshout' });

        // Twitter
        // this.meta.updateTag({ name: 'twitter:card', content: 'Spaeshout' });  // TO-DO set default card
        this.meta.updateTag({ name: 'twitter:title', content: 'Spaeshout' });
        this.meta.updateTag({ name: 'twitter:description', content: 'Spaceshout – add unique places on maps, creating one-of-a-kind collections, and then share them with others in a very simple way.' });
        // this.meta.updateTag({ name: 'twitter:image', content: 'Spaeshout' }); // TO-DO set default image
        // this.meta.updateTag({ name: 'twitter:site', content: 'Spaeshout' }); // TO-DO set default site
    }

    private setMetaTags(collection: CollectionContentDTO): void {
        // Open graph FB/Instagram/Linkedin
        this.meta.updateTag({ property: 'og:title', content: collection.title ?? 'Spaeshout' });
        this.meta.updateTag({ property: 'og:type', content: 'website' });
        this.meta.updateTag({ property: 'og:url', content: 'https://spceshout.com/' + collection.title.replace(/\s+/g, '-') });
        if (collection?.image) {
            this.meta.updateTag({ property: 'og:image', content: `https://res.cloudinary.com/${environment.cloudinaryName}/image/upload/t_full/` + collection.image });
        }
        this.meta.updateTag({ property: 'og:description', content: collection?.description ?? 'Spaceshout – add unique places on maps, creating one-of-a-kind collections, and then share them with others in a very simple way.' });
        this.meta.updateTag({ property: 'og:site_name', content: 'Spaeshout' });

        // Twitter
        // this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' }); // TO-DO set card
        this.meta.updateTag({ name: 'twitter:title', content: collection.title ?? 'Spaeshout' });
        this.meta.updateTag({ name: 'twitter:description', content:  collection.description ?? 'Spaceshout – add unique places on maps, creating one-of-a-kind collections, and then share them with others in a very simple way.' });
        // this.meta.updateTag({ name: 'twitter:image', content: 'Spaeshout' }); // TO-DO set image
        // this.meta.updateTag({ name: 'twitter:site', content: 'Spaeshout' }); // TO-DO set site
    }
}
