import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SearchType } from 'app_code/app/shared/interfaces/search-type.enum';
import { PointContentDTO } from 'app_code/app/shared/model/point-dto';

@Component({
  selector: 'shout-text-point',
  templateUrl: './text-point.component.html',
  styleUrls: ['./text-point.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextPointComponent {

  @ViewChild('textPointBody') textPointBody!: ElementRef;
  @ViewChild('textPointPointer') textPointPointer!: ElementRef;
  
  @Input() point: PointContentDTO;
  @Input() searchType: SearchType;
  @Input() hasSelectedItem: boolean;
  @Input() isCollectionHintVisible: boolean;
  @Input() isWelcome: boolean;
  @Input() description: string;
  @Input() showMinimalisticVersion: boolean;
  @Input() blockEvents: boolean;
  @Input() hidePoisCounter: boolean;

  @Output() clicked = new EventEmitter<void>();
  @Output() wheeled = new EventEmitter<WheelEvent>();
  @Output() poisCountClick = new EventEmitter<void>();

  SearchType = SearchType;

  constructor(private router: Router) {}

  get shouldShowPoisCounter(): boolean {
    return this.isCollectionHintVisible && this.point?.poisCount && this.point.poisCount > 1 && !this.showMinimalisticVersion && !this.hidePoisCounter;
  }

  onPointClick(): void {
    if (this.blockEvents === null || this.blockEvents === true) {
      this.router.navigateByUrl('');
      return;
    }
    this.clicked.emit();
  }

  onWheel(ev: WheelEvent): void {
    this.wheeled.emit(ev);
  }

  onPoisCountClick(ev): void {
    ev.stopPropagation();
    ev.preventDefault();
    this.poisCountClick.emit();
  }
}
