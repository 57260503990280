import { ApplicationRef, ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, NgZone, Renderer2, RendererFactory2 } from '@angular/core';
import { PointOnMapMarkerComponent } from '../components/point-on-map-marker/point-on-map-marker.component';
import * as L from 'leaflet';
import { MapService } from './map.service';
import { MixpanelService } from 'app_code/app/shared/services/login/mixpanel.service';
import { PointService } from 'app_code/app/shared/services/point/point.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Log } from 'ng2-logger/browser';
import { PointContentDTO } from 'app_code/app/shared/model/point-dto';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreDataService } from 'app_code/app/shared/services/store/store-data.service';
import { MapButtonsMode } from '../model/map-buttons-mode';
import { MOBILE_WIDTH_TRESHOLD } from 'app_code/app/shared/constants';
import { SearchType } from 'app_code/app/shared/interfaces/search-type.enum';

@Injectable()
export class PointOnMapMarkerService {

  pointAddedToMap$ = new Subject<void>();

  private log = Log.create('PointOnMapMarkerService');
  private renderer: Renderer2;
  private markers = new Array<L.Marker<any>>();
  private popups = new Array<L.Popup>();
  private pointIdToComponentRefMap = new Map<number, ComponentRef<PointOnMapMarkerComponent>>();

  get pointAddedToMap() {
    return this.pointAddedToMap$.asObservable();
  }

  constructor(
    private mapService: MapService,
    private mixpanelService: MixpanelService,
    private pointService: PointService,
    private storeDataService: StoreDataService,
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private router: Router,
    private zone: NgZone,
    private activatedRoute: ActivatedRoute,
    rendererFactory: RendererFactory2
  ) { 
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  get allMarkers(): Array<L.Marker<any>> {
    return this.markers;
  }

  constructPointPopup(point: PointContentDTO, isWelcome = false): void {
    const destroy$ = new Subject<void>();

    let marker = L.marker(
      [point.poi.lat, point.poi.lng],
      {
        icon: L.icon({
          iconUrl: 'assets/1x1.png'
        })
      },
      
    );
    this.markers.push(marker);
    const componentRef = this.createPopupComponent(point, destroy$, marker, isWelcome)
    const domElem = (componentRef.hostView as any).rootNodes[0] as HTMLElement;
    this.pointIdToComponentRefMap.set(point.poi.id, componentRef);

    // Attach component to popup
    let popup = L.popup({
      autoPan: false,
      closeButton: false,
      autoClose: false,
      className: point.poi.image ? 'popup__image-popup' : 'popup__text-popup'
    }).setContent(domElem);
    this.popups.push(popup);
  
    popup['uniqueId'] = point.poi.id;
    marker.bindPopup(popup);
  
    // Open the popup as soon as the marker is added to the map
    marker.on('add', () => {
      marker.openPopup();
    });

    // Clean up the component when it's no longer needed
    marker.on('remove', () => {
      this.log.data('cleaning point with id: ', popup['uniqueId']);
      destroy$.next();
      destroy$.complete();
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
      const idx = this.markers.findIndex(mar => {
        const latLng = mar.getLatLng();
        return latLng.lat === point.poi.lat && latLng.lng === point.poi.lng
      });
      this.pointIdToComponentRefMap.delete(point.poi.id);
      this.markers.splice(idx, 1);
    });

    // Trigger change detection manually
    this.zone.run(() => {
      const changeDetectorRef = componentRef.injector.get(ChangeDetectorRef);
      changeDetectorRef.markForCheck();
    });
  }

  pointPopupClicked(point: PointContentDTO): void {
    if (this.pointService.getPageToPointsList().size > 0) {
      this.mixpanelService.openPoint(point);
      this.log.data('clicked point ID: ' + point.poi.id);
      //we don't have to call here REST and update views in the DB
      //because view are updated when call to fetch this comment is made
      //and this comment will fetch by popup
      point.poi.views = point.poi.views + 1;
      this.pointService.lastClickedPointOnMap$.next(point);
      if (this.router.url !== '/view-points') {
        this.zone.run(() => {
          if (window.innerWidth >= MOBILE_WIDTH_TRESHOLD) {
            this.mapService.blockFetchingNewPoints = true;
            this.mapService.setMapSizeBeforeScaling();
            this.storeDataService.setViewPointsPanelToggle(true);
          }
          this.router.navigateByUrl('/view-points');
        });
      }
    } else {
      this.log.error('no points, wall not opened');
    }
  }

  setPointPopupHighlight(id: number) {
    this.log.info('setPointPopupHighlight: ', id);
    this.mapService.markers.eachLayer((marker: any) => {
      if (+marker._popup.uniqueId === id) {
        this.renderer.addClass(marker.getPopup().getElement(), 'popup__selected');
      }
    })
  }

  removeAllMarkers(): void {
    this.mapService.markers.clearLayers();
  }

  updatePopupComponent(point: PointContentDTO): void {
    if (this.pointIdToComponentRefMap.has(point.poi.id)) {
      let instance = this.pointIdToComponentRefMap.get(point.poi.id)?.instance;
      instance.point = point;
      instance.cd.markForCheck();
    }
  }

  private createPopupComponent(point: PointContentDTO, destroy$: Subject<void>, marker: L.Marker, isWelcome = false): ComponentRef<PointOnMapMarkerComponent> {
    // Dynamically create the PopupContentComponent
    const factory = this.resolver.resolveComponentFactory(PointOnMapMarkerComponent);
    const componentRef = factory.create(this.injector);

    // Initialize Inputs
    componentRef.instance.point = point;
    componentRef.instance.searchType = this.mapService.searchCriteria.searchType;
    componentRef.instance.hasSelectedItem = !!this.mapService.searchCriteria.selectedItem;
    componentRef.instance.isWelcome = isWelcome;
  
    componentRef.instance.pointClicked.pipe(
      takeUntil(destroy$)
    ).subscribe(point => this.pointPopupClicked(point));

    componentRef.instance.ready.pipe(
      takeUntil(destroy$)
    ).subscribe(_ => this.addMarkerToMap(marker));

    componentRef.instance.bringToFront.pipe(
      takeUntil(destroy$)
    ).subscribe(_ => {
      marker.getPopup().bringToFront();
    });

    if (isWelcome) {
      componentRef.instance.close.pipe(
        takeUntil(destroy$)
      ).subscribe(_ => {
        this.removeAllMarkers();
        this.mapService.mapButtonsMode.set(this.storeDataService.isEmbedded.value ? MapButtonsMode.EMBEDDED : MapButtonsMode.ALL);
        this.storeDataService.setToggleLeftSideBar(true);
        this.storeDataService.setMapRefresh();
        this.mapService.canUpdateMarkersOnMap = true;
        this.mapService.searchCriteria.searchType = SearchType.ADDRESS;
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: undefined
        });
      });
    }

    // Add to DOM
    this.appRef.attachView(componentRef.hostView);

    return componentRef;
  }

  private addMarkerToMap(marker): void {
    this.mapService.markers.addLayer(marker);
    this.pointAddedToMap$.next();
  }
}
