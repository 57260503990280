import { PointContentDTO } from '../model/point-dto';

export interface ActivePointOnMap {
    point: PointContentDTO;
    size: PointOnMapSize;
}

export enum PointOnMapSize {
    SMALL = 'SMALL',
    FULL = 'FULL'
}
