<div 
  class="point-on-map point-on-map--text"
  [ngClass]="{
    'point-on-map--minimalistic': showMinimalisticVersion,
    'point-resize': !showMinimalisticVersion,
    'point-shrink': showMinimalisticVersion,
    'point-on-map--text--loading': blockEvents}"
  (click)="onPointClick()" 
  (wheel)="onWheel($event)"
>
  <div class="point-on-map__text" [ngClass]="{'point-on-map--text--constant-size': isWelcome}">
    <div *ngIf="point?.poi?.likes > 0" class="point-on-map__text__likes">
      <shout-svg-icons [name]="'markerFilledHearth'" ></shout-svg-icons>
      <div class="point-on-map__text__likes__number">{{point.poi.likes > 99 ? '99+' : point.poi.likes}}</div>
    </div>

    <div *ngIf="point.poi.title" class="point-on-map__text__title" [attr.title]="point.poi.title">{{point.poi.title}}</div>
    <div 
      *ngIf="description && (!showMinimalisticVersion || (showMinimalisticVersion && !point.poi.title))" 
      class="point-on-map__text__description" 
      [ngClass]="{'single-line': !point.poi.title && showMinimalisticVersion, 'truncate-multiline': !showMinimalisticVersion}"
      [attr.title]="point.poi.text" 
      [innerHTML]="description | tagPipe | urlPipe | emailPipe"
    >
    </div>
  </div>

  <div *ngIf="!isCollectionHintVisible && (searchType !== SearchType.COLLECTION || !hasSelectedItem)" class="point-on-map__collection-count">
    <span>{{point.numberOfPois > 99 ? '99+' : point.numberOfPois}}</span>
  </div>

  <div class="point-on-map__pointer" [ngClass]="{'pointer-on-map__pointer--poisCount': shouldShowPoisCounter}">
    <div class="point-on-map__pointer__element">
        @if (shouldShowPoisCounter) {
          <spaceshout-button
            [size]="'roundedAuto'"
            [label]="point.poisCount > 100 ? '100+' : point.poisCount"
            (clicked)="onPoisCountClick($event)"
          >
          </spaceshout-button>
        } @else {
          <shout-svg-icons [name]="'mapPointRectangle'"></shout-svg-icons>
        }
    </div>  
  </div>
</div>


