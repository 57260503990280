import { Injectable } from '@angular/core';
import { environment } from 'app_code/environments/environment';
import * as mixpanel from 'mixpanel-browser';
import { AuthService } from '../auth/auth.service';
import { SearchCriteria } from 'app_code/app/shared/interfaces/comment-search-criteria';
import { SearchType } from 'app_code/app/shared/interfaces/search-type.enum';
import { PointContentDTO } from 'app_code/app/shared/model/point-dto';
import { ReplyContentDTO } from 'app_code/app/shared/model/reply-dto';

@Injectable()
export class MixpanelService {

  constructor(private authSerice: AuthService) { }

  init(): void {
    if(environment.production) {
      mixpanel.init('0b5c05deb98fb5ed0ee67e3ec7464774');
    } else {
      // mixpanel.init('2500ba06c2c5127d7f8db7a6fc55cb30');
      mixpanel.init('uncomment above to turn on DEV logs');
    }
  }

  public login(loginType: string): void {
    mixpanel.identify(this.authSerice.getUserId());
    mixpanel.people.set({
      $name: this.authSerice.getUsername(),
      $email: this.authSerice.getEmail(),
      lastLogin: new Date()
    });
    mixpanel.people.set_once({
      firstLogin: new Date()
    });
    mixpanel.track("Log in", {
      loginType: loginType
    });
  }

  public deleteAccount(): void {
    mixpanel.people.set_once({
      deletedOn : new Date(),
      accountDeleted: true
    });
    mixpanel.track("Account deleted");
  }

  public mapClicked(): void {
    mixpanel.people.set_once({
      mapClickFirst : new Date()
    });
    mixpanel.people.set({
      mapClickLast : new Date()
    });
    mixpanel.people.increment("mapClickTotal");
    mixpanel.track("Map clicked");
  }

  public addPoint(point: PointContentDTO): void {
    mixpanel.people.set_once({
      firstComment : new Date()
    });
    mixpanel.people.set({
      lastComment : new Date()
    });
    mixpanel.people.increment("commentAddTotal");
    mixpanel.track("Add comment", {
      commentShare: point.poi.share,
      commentText: point.poi.text != null,
      commentImage: point.poi.image != null
    });
  }

  public openPoint(point: PointContentDTO): void {
    mixpanel.people.set_once({
      mapMarkerOpenFirst : new Date()
    });
    mixpanel.people.set({
      mapMarkerOpenLast : new Date()
    });
    mixpanel.people.increment("mapMarkerOpenTotal");
    mixpanel.track("Open map marker", {
      mapMarkerOpenShare: point.poi.share,
      mapMarkerOpenText: point.poi.text != null,
      mapMarkerOpenImage: point.poi.image != null
    });
  }

  public addReply(reply: ReplyContentDTO, share: string): void {
    mixpanel.people.set_once({
      replyFirst : new Date()
    });
    mixpanel.people.set({
      replyLast : new Date()
    });
    mixpanel.people.increment("replyTotal");
    mixpanel.track("Add reply", {
      replyShare: share,
      replyText: reply.text != null,
      replyImage: reply.image != null
    });
  }

  public likePoint(point: PointContentDTO): void {
    mixpanel.people.set_once({
      commentFirstLike : new Date()
    });
    mixpanel.people.set({
      commentLastLike : new Date()
    });
    mixpanel.people.increment("commentTotalLikes");
    mixpanel.track("Like comment", {
      commentLikeShare: point.poi.share,
      commentLikeText: point.poi.text != null,
      commentLikeImage: point.poi.image != null
    });
  }

  public likeReply(reply: ReplyContentDTO, commentShare: string): void {
    mixpanel.people.set_once({
      replyFirstLike : new Date()
    });
    mixpanel.people.set({
      replyLastLike : new Date()
    });
    mixpanel.people.increment("replyTotalLikes");
    mixpanel.track("Like reply", {
      replyLikeShare: commentShare,
      replyLikeText: reply.text != null,
      replyLikeImage: reply.image != null
    });
  }

  public shareMap(searchCriteria: SearchCriteria) : void {
    mixpanel.people.set_once({
      shareMapFirst : new Date()
    });
    mixpanel.people.set({
      shareMapLast : new Date()
    });
    mixpanel.people.increment("shareMapTotal");
    mixpanel.track("Share map", {
      shareMapType: this.shareType(searchCriteria)
     });
  }

  public sharePoint(point: PointContentDTO): void {
    mixpanel.people.set_once({
      shareCommentFirst : new Date()
    });
    mixpanel.people.set({
      shareCommentLast : new Date()
    });
    mixpanel.people.increment("shareCommentTotal");
    mixpanel.track("Share comment", {
      shareCommentShare: point.poi.share,
      shareCommentText: point.poi.text != null,
      shareCommentImage: point.poi.image != null
    });
  }

  public openLink(linkType: string): void {
    mixpanel.people.set_once({
      shareLinkOpenFirst : new Date()
    });
    mixpanel.people.set({
      shareLinkOpenLast : new Date()
    });
    mixpanel.people.increment("shareLinkOpenTotal");
    mixpanel.track("Open link", {
      shareLinkOpenType: linkType
    });
  }

  public friends(connect: string): void {
    mixpanel.track("Friends", {
      connect: connect
    });
  }

  public search(searchCriteria: SearchCriteria): void {
    mixpanel.track("Search", {
      hidePublic: searchCriteria.hidePublic,
      searchType: this.shareType(searchCriteria)
    });
  }

  public movePage(direction: string, pageNumber: number, searchCriteria: SearchCriteria): void {
    mixpanel.people.set_once({
      movePageDone : true
    });
    mixpanel.track("Search", {
      movePageDirection: direction,
      movePageNumber: pageNumber,
      hidePublic: searchCriteria.hidePublic,
      movePageSearchType: this.shareType(searchCriteria)
    });
  }

  private shareType(searchCriteria: SearchCriteria): string {
    if(searchCriteria.selectedItem) {
      switch (searchCriteria.searchType) {
        case SearchType.COLLECTION:
          return "COLLECTION"
          break;
        case SearchType.POI:
          return "POI"
          break;
        default:
          return "PLACE"
          break;
      }
    } else {
      return "PLACE";
    }
  }
}
